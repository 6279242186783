import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import { Accordion, AccordionItem } from 'react-light-accordion';

export const Faqs = () => {
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
                    <div className='banner booknow filter m-0 h-120'>                     
                    </div>
            </section>
            <section id='faq'>
        <div className='faq'>
            <div className='container'>
                <div className='row'>
                    <div className='web-container'>
                    <div className='heading' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <h4>Frequently Asked Questions</h4>
                                        </div>
                                        <div className='faqcont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <Accordion atomic={true}>

                                                <AccordionItem title="1. Why do I need a strong Wifi password?">
                                                    <div className='accordiancont'>
                                                        <p>If someone hops on to your Wifi, then there is more at stake than just checking e-mails or streaming videos. This person can intercept all the data flowing in and out of your network. To prevent any unauthorized use, use a sufficiently strong password. A strong password consists of at least six characters (and the more characters, the stronger the password) that are a combination of letters, numbers and symbols (@, #, $, %, etc.). Passwords are typically case-sensitive, so a strong password contains letters in both uppercase and lowercase.</p>
                                                    </div>
                                                </AccordionItem>
                                              <AccordionItem title="2. Is it important to switch off the modem/Wifi on devices when not in use?">
                                                    <div className='accordiancont'>
                                                        <p>Switch off the modem when not in use as it not only saves electricity, but improves your network security. When the device is off, hackers cannot target them. A lot of websites and apps keep consuming data, even when you are not using them, thus it is important to turn off the Wifi on your devices when not in use.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="3. Does using multiple devices affect my data consumption?">
                                                    <div className='accordiancont'>
                                                        <p>When multiple devices like smartphones, tablets, PCs, laptops, HDTVs are latched on to a single Wifi, the data consumption significantly increases. Be aware of the devices connecting to your Wifi and turn off the Wifi on the device when not in use as most apps and a lot of websites keep consuming data, even when you are not using them.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="4. How do I get the connection?">
                                                    <div className='accordiancont'>
                                                        <p>Click here to register online Our Executive will get in touch with you soon.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="5. How do I choose the speed/tariff plan that’s right for me? ">
                                                    <div className='accordiancont'>
                                                        <p>his will depend on the purpose for which you will be using the broadband connection. Select from Best Fit Plan</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="6. Useful Typography Elements">
                                                    <div className='accordiancont'>
                                                        <p>There are many variations of but the majority have suffered alteration in some form, by injected humour, or randomised words which looks.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="7. Useful Typography Elements">
                                                    <div className='accordiancont'>
                                                        <p>There are many variations of but the majority have suffered alteration in some form, by injected humour, or randomised words which looks.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="8. Why should I choose Net+broadband services?">
                                                    <div className='accordiancont'>
                                                        <p>Whether you are an individual user (@home) or a corporate user (@work) Net+ Broadband Services has only the best to offer.Below mentioned features make us Unique:</p>
                                                        <ul>
                                                            <li>More Speed</li>
                                                            <li>More Data</li>
                                                            <li>Less Rates</li>
                                                            <li>Door Step Services</li>
                                                            <li>Fault Free Services</li>
                                                            <li>24*7 Customer Support</li>
                                                            <li>Quick Services</li>
                                                            </ul>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="9. What is Fair Usage Policy (FUP)?">
                                                    <div className='accordiancont'>
                                                        <p>A very small number of customers use an excessive amount of the network bandwidth, to the extent that it can impair the experience of others. The intent of our Fair Usage Policy is to provide the optimum internet experience to all customers.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="10. Appellate Authority?">
                                                    <div className='accordiancont'>
                                                        <p>In order to provide appellate redressal of the complaint please provide your complaint docket number and customer id in the email along with the case history and any other relevant documents.</p>
                                                        <p>Complaint will only be accepted if the complaint has not been resolved upto satisfaction by Level 1 and Level 2 Customer care support.</p>
                                                        <p>Name: Col. R S Perhar</p>
                                                        <p>Designation: President-Strategy and Planning</p>
                                                        <p>Email ID: <a href='mailto:appellate.authority@netplus.co.in'>appellate.authority@netplus.co.in</a></p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="11. Nodal Officer">
                                                    <div className='accordiancont'>
                                                        <p>Name: Mr. Darshan DS Rooprai</p>
                                                        <p>Designation: Nodal Officer</p>
                                                        <p>Email ID: <a href='mailto:nodalofficer@netplus.co.in'>nodalofficer@netplus.co.in</a></p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="12. What would happen with the speed of service after I reach the FUP?">
                                                    <div className='accordiancont'>
                                                        <p>On reaching the fair usage level, the plan speed would be optimized to Post FUP speed mentioned (as per your plan), for the rest of the month/ billing cycle as applicable. Please note that the speeds would be upgraded to the normal speeds at the start of the next month /billing cycle as applicable. While the subscriber has always the choice of choosing a right plan basis their individual requirements which is available in our Best Fit Plansection.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="13. Can I opt for a Top-up after I reach my FUP?">
                                                    <div className='accordiancont'>
                                                        <p>We have a choice of Top up plans that are available basis individual requirements.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="14. What is the impact of Fair Usage Policy on data transfer limits?">
                                                    <div className='accordiancont'>
                                                        <p>We assure you that the data transfer limits remain unlimited and there will be no change on the same.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="15. How do I get a copy of my bill /statement?">
                                                    <div className='accordiancont'>
                                                        <p>View Bill</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="16. How do I get to know my data usage?">
                                                    <div className='accordiancont'>
                                                        <p>View Data usage</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="17. I want very high data transfer limits. Is this possible with Netplus?">
                                                    <div className='accordiancont'>
                                                        <p>We have plans to suit the needs of all customers. Choose the plan from Best Fit Plan</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="18. How do I make my payments to Netplus?">
                                                    <div className='accordiancont'>
                                                       <p>You have following options for payment”</p>
                                                       <p>Find Your Local Cable Operator Number</p>
                                                       <p>(Pick up from home facility available)</p>
                                                       <p>Pay online</p>
                                                       <p>Our Stores</p>
                                                       <p>Our Cheque Collection Boxes</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="19. After payment, would my account get activated instantly?">
                                                    <div className='accordiancont'>
                                                        <p>If the account is not active at the time of payment, it would require a minimum of 4 workings hours to a maximum of 72 Hours for the account to become active, depending upon the mode of payment.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="20. What happens if I shift my premises?">
                                                    <div className='accordiancont'>
                                                        <p>Shifting Request</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="21. Can I change my plan?">
                                                    <div className='accordiancont'>
                                                        <p>Yes, you can change your Broadband plan at any time. Check Best Fit Plan</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="22. Can the help desk help solve problems with my own computer/ hardware?">
                                                    <div className='accordiancont'>
                                                        <p>No. The helpdesk is there to help you with problems relating specifically to the Broadband service from Netplus and the hardware/software provided by Netplus. If you have purchased a product where hardware was not provided as part of the service, the helpdesk will not be able to assist you with it, and you should contact your hardware supplier.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="23. How will I know when I have cross my data transfer limits?">
                                                    <div className='accordiancont'>
                                                        <p>We will send you an email/SMS when you reach 80% of your plan’s data transfer limit and again when you reach 100%. In addition to that, you can check your data usage anytime from View Usage.</p>
                                                    </div>
                                                </AccordionItem>
                                            </Accordion>

                                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
